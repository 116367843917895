<template>
<!--  首页荣衔进度条组件-->
  <div >
      <p>{{$t('Region')}}</p>
      <div class="whole">
        <!--    营销经理-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget1)" :percentage="bigtarget1"></el-progress>{{this.$t('ru.mimember.cardtype.1')}}</div>
        <!--    高级营销经理-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget2)" :percentage="bigtarget2"></el-progress>{{$t('ru.mimember.cardtype.2')}}</div>
        <!--    资深营销经理-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget3)" :percentage="bigtarget3"></el-progress>{{$t('ru.mimember.cardtype.3')}}</div>
        <!--    营销总监-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget4)" :percentage="bigtarget4"></el-progress>{{$t('ru.mimember.cardtype.4')}}</div>
        <!--    高级营销总监-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget5)" :percentage="bigtarget5"></el-progress>{{$t('ru.mimember.cardtype.5')}}</div>
        <!--    资深营销总监-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(bigtarget6)" :percentage="bigtarget6"></el-progress>{{$t('ru.mimember.cardtype.6')}}</div>
      </div>
      <p>{{$t('residential.quarters')}}</p>
      <div class="whole">
        <!--   营销经理 -->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget1)" :percentage="smalltarget1"></el-progress>{{$t('ru.mimember.cardtype.1')}}</div>
        <!--    高级营销经理-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget2)" :percentage="smalltarget2"></el-progress>{{$t('ru.mimember.cardtype.2')}}</div>
        <!--   资深营销经理 -->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget3)" :percentage="smalltarget3"></el-progress>{{$t('ru.mimember.cardtype.3')}}</div>
        <!--   营销总监 -->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget4)" :percentage="smalltarget4"></el-progress>{{$t('ru.mimember.cardtype.4')}}</div>
        <!--   高级营销总监 -->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget5)" :percentage="smalltarget5"></el-progress>{{$t('ru.mimember.cardtype.5')}}</div>
        <!--    资深营销总监-->
        <div class="single"><el-progress :text-inside="true" :stroke-width="18" :color="getProgressColor(smalltarget6)" :percentage="smalltarget6"></el-progress>{{$t('ru.mimember.cardtype.6')}}</div>
      </div>
  </div>
</template>

<script>

import {getCardType} from "@/api/rus/api";
import {mapGetters} from "vuex";

export default {
  name: 'HonorProgressBar',
  data(){
    return{
      schedule:0,//大区业绩进度条
      schedule1:0,//小区业绩进度条
      target:0,//进度条目标
      bigtarget1:0,//大区各业绩
      bigtarget2:0,//大区各业绩
      bigtarget3:0,//大区各业绩
      bigtarget4:0,//大区各业绩
      bigtarget5:0,//大区各业绩
      bigtarget6:0,//大区各业绩
      smalltarget1:0,//小区各业绩
      smalltarget2:0,//小区各业绩
      smalltarget3:0,//小区各业绩
      smalltarget4:0,//小区各业绩
      smalltarget5:0,//小区各业绩
      smalltarget6:0,//小区各业绩
      tourism2500:0,//旅游2500
      tourism4000:0,//旅游4000
    }
  },
  props: {
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'ckCode',
      'companyCode',
    ]),
  },
  created() {
    this.getinformation()
  },
  methods: {
    getinformation(){
      getCardType({'memberNo': this.userCode}).then(res => {
        console.log(res.data,'123123')
        switch (res.data.nextCardType){
          case '1':
            this.schedule = ( res.data.netsell / 4000 * 100 )
            this.schedule1 = ( res.data.netsellqita /4000 * 100 )
            console.log(this.schedule ,this.schedule1,'111111111111111')
            this.target = 1
            this.bigtarget1 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.bigtarget2 = 0
            this.bigtarget3 = 0
            this.bigtarget4 = 0
            this.bigtarget5 = 0
            this.bigtarget6 = 0
            this.smalltarget1 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            this.smalltarget2 = 0
            this.smalltarget3 = 0
            this.smalltarget4 = 0
            this.smalltarget5 = 0
            this.smalltarget6 = 0
            break
          case '2':
            this.schedule =  (res.data.netsell / 8000 * 100 )
            this.schedule1 =  (res.data.netsellqita /8000 * 100 )
            this.target = 2
            this.bigtarget1 = 100
            this.bigtarget2 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.bigtarget3 = 0
            this.bigtarget4 = 0
            this.bigtarget5 = 0
            this.bigtarget6 = 0
            this.smalltarget1 = 100
            this.smalltarget2 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            this.smalltarget3 = 0
            this.smalltarget4 = 0
            this.smalltarget5 = 0
            this.smalltarget6 = 0
            break
          case '3':
            this.schedule =  (res.data.netsell / 20000 * 100 )
            this.schedule1 = ( res.data.netsellqita /20000 * 100 )
            this.target = 3
            this.bigtarget1 = 100
            this.bigtarget2 = 100
            this.bigtarget3 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.bigtarget4 = 0
            this.bigtarget5 = 0
            this.bigtarget6 = 0
            this.smalltarget1 = 100
            this.smalltarget2 = 100
            this.smalltarget3 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            this.smalltarget4 = 0
            this.smalltarget5 = 0
            this.smalltarget6 = 0
            break
          case '4':
            this.schedule =  (res.data.netsell / 40000 * 100 )
            this.schedule1 =  (res.data.netsellqita /40000 * 100 )
            this.target = 4
            this.bigtarget1 = 100
            this.bigtarget2 = 100
            this.bigtarget3 = 100
            this.bigtarget4 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.bigtarget5 = 0
            this.bigtarget6 = 0
            this.smalltarget1 = 100
            this.smalltarget2 = 100
            this.smalltarget3 = 100
            this.smalltarget4 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            this.smalltarget5 = 0
            this.smalltarget6 = 0
            break
          case '5':
            this.schedule =  (res.data.netsell / 80000 * 100 )
            this.schedule1 = ( res.data.netsellqita /80000 * 100 )
            this.target = 5
            this.bigtarget1 = 100
            this.bigtarget2 = 100
            this.bigtarget3 = 100
            this.bigtarget4 = 100
            this.bigtarget5 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.bigtarget6 = 0
            this.smalltarget1 = 100
            this.smalltarget2 = 100
            this.smalltarget3 = 100
            this.smalltarget4 = 100
            this.smalltarget5 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            this.smalltarget6 = 0
            break
          case '6':
            this.schedule =  (res.data.netsell / 160000 * 10 )
            this.schedule1 =  (res.data.netsellqita /160000 * 100 )
            this.target = 6
            this.bigtarget1 = 100
            this.bigtarget2 = 100
            this.bigtarget3 = 100
            this.bigtarget4 = 100
            this.bigtarget5 = 100
            this.bigtarget6 = this.schedule >= 100 ? 100 : Math.floor(this.schedule)
            this.smalltarget1 = 100
            this.smalltarget2 = 100
            this.smalltarget3 = 100
            this.smalltarget4 = 100
            this.smalltarget5 = 100
            this.smalltarget6 = this.schedule1 >= 100 ? 100 : Math.floor(this.schedule1)
            break
          default:
            this.schedule = 0
            this.schedule1 =  0
            this.target = 0
            this.bigtarget1 = 0
            this.bigtarget2 = 0
            this.bigtarget3 = 0
            this.bigtarget4 = 0
            this.bigtarget5 = 0
            this.bigtarget6 = 0
            this.smalltarget1 = 0
            this.smalltarget2 = 0
            this.smalltarget3 = 0
            this.smalltarget4 = 0
            this.smalltarget5 = 0
            this.smalltarget6 = 0
            break

        }
        console.log(this.listQuery.cardType,this.bigtarget1,this.bigtarget2,this.bigtarget3,this.bigtarget4,this.bigtarget5,this.bigtarget6)
      }).catch(() => {
      })
    },
    getProgressColor(percentage) {
      // 根据进度设置颜色
      if (percentage < 30) {
        return '#cc0033'; // 红色
      } else if (percentage < 70) {
        return '#ff9900'; // 橙色
      } else {
        return '#67c23a'; // 绿色
      }
    },
  }
}
</script>

<style scoped lang="scss">
.pagination-container {
  background: #fff;
  text-align: right;
}
.pagination-container.hidden {
  display: none;
}
.whole{
  margin: 10px 0;
  position: relative;
  display: flex;
}
.single{
  flex: 1;
}
::v-deep .el-progress-bar__innerText{
  color: #000;
}
.box{
  border: 1px solid #000;
  border-radius: 20px;
  padding: 20px;
  text-align: left;
}
</style>
