import request from '@/utils/request'

// 兑换码查询 分页
export function pageByDTO(query) {
    return request({
        url: '/procQualification/pageByDTO',
        method: 'GET',
        params: query
    })
}

// 兑换码查询 集合
export function listByDTO(query) {
    return request({
        url: '/procQualification/listByDTO',
        method: 'GET',
        params: query
    })
}

// 验证兑换码是否可用
export function procQualificationCanUse(query) {
    return request({
        url: '/procQualification/procQualificationCanUse',
        method: 'GET',
        params: query
    })
}

// 兑换码报单
export function procQualificationSaveOrder(data) {
    return request({
        url: '/procQualification/procQualificationSaveOrder',
        method: 'post',
        data
    })
}
